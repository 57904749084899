import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    
<div class="containers">

  <footer class="text-white text-center text-lg-start" style={{backgroundColor:"black"}} >
    
    <div class="container p-4">
      
      <div class="row mt-4">
        
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <img src="https://real-estate-web.pages.dev/logo.png" alt="" />
          <br />
          <p>Our vision is to make all people the best place to live for them.</p>
          <p>“Real estate is an imperishable asset, ever increasing in value..</p>
        </div>
        
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          
          <ul class="fa-ul" >
            <br />
            <li class="mb-3">
              <span class="fa-li"><i class="fas fa-home"></i></span><span class="ms-2">New York, NY 10012, US</span>
            </li>
            <li class="mb-3">
              <span class="fa-li"><i class="fas fa-envelope"></i></span><span class="ms-2">info@example.com</span>
            </li>
            <li class="mb-3">
              <span class="fa-li"><i class="fas fa-phone"></i></span><span class="ms-2">+ 01 234 567 88</span>
            </li>
            <li class="mb-3">
              <span class="fa-li"><i class="fas fa-print"></i></span><span class="ms-2">+ 01 234 567 89</span>
            </li>
          </ul>
        </div>
        

        
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0" style={{backgroundColor:"black"}} >
            <br />
          <h5 class="text-uppercase mb-4">Opening hours</h5>

          <table class="table text-center table-dark" style={{backgroundColor:"black"}}>
            <tbody class="font-weight-normal">
              <tr>
                <td>Mon - Thu:</td>
                <td>8am - 9pm</td>
              </tr>
              <tr>
                <td>Fri - Sat:</td>
                <td>8am - 1am</td>
              </tr>
              <tr>
                <td>Sunday:</td>
                <td>9am - 10pm</td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
      
    </div>
    

    
  </footer>
  
</div>

  )
}

export default Footer