import React from 'react'
import Header from './Components/Header/Header'
import Hero from './Components/Hero/Hero'
import './App.css'
import Companies from './Components/Companies/Companies'
import Residencies from './Components/Residencies/Residencies'
import Value from './Components/Value/Value'
import Contact from './Components/Contact/Contact'
import Footer from './Components/Footer/Footer'
const App = () => {
  return (
    <div className='App'>
      <div className='white-gradient'>
      <Header/>
      <Hero/>
      <Companies/>
      <Residencies/>
      <Value/> 
      <Contact/>
      <Footer/>
    </div>
    </div>
  )
}

export default App