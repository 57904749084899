import React , {useState}from 'react'
import './Value.css'
import {Accordion, AccordionItem, AccordionItemHeading,AccordionItemButton,AccordionItemPanel,AccordionItemState} from 'react-accessible-accordion';
import "react-accessible-accordion/dist/fancy-example.css"
import {MdOutlineArrowDropDown} from 'react-icons/md'
import data from '../../utils/accordion'


const Value = () => {
  return (
    <section className='v-wrapper'>
        <div className="paddings innerwidth flexCenter v-container">
            <div className="v-left">
                <div className="image-container">
                    <img src="https://real-estate-web.pages.dev/value.png" alt="" />
                </div>
            </div>
            <div className="flexColStart v-right">
                <span className='orangeText'>Our Value</span>
                <span className='primaryText'>Value We Give to You</span>
                <span className='secondaryText' style={{fontSize:"20px"}}> We always ready to help by providijng the best services for you.
We beleive a good blace to live can make your life better
                    <br/>
                </span>
                <span className='primaryText'>Best interest rates on the market</span>
                <span className='secondaryText' style={{fontSize:"20px"}}> Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.
                    <br/>
                </span>
                <span className='primaryText'>Prevent unstable prices</span>
                <span className='secondaryText' style={{fontSize:"20px"}}> Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.
                    <br/>
                </span>
            </div>
        </div>
    </section>
  )
}

export default Value