// import React from 'react'
import './Residencies.css'
// import {Swiper,SwiperSlide, useSwiper} from 'swiper/react'
// import data from "../../utils/slider.json"
// import { sliderSetting } from '../../utils/common'


// const Residencies = () => {
//   return (
//     <section className='r-wrapper'>
//         <div className="paddings innerWidth r-container">
//             <div className="r-head flexColStart">
//                 <span className='orangeText'>Best Choices</span>
//                 <span className='primaryText'>Popular Residencies</span>
//             </div>
//             <Swiper {...sliderSetting}>
//                 <SliderButton/>
//                 {
//                     data.map((card,i)=>(
//                         <SwiperSlide key={i}>
//                             <div className="flexColStart r-card">
//                                 <img src={card.image} alt="" />
//                                 <span className='secondaryText r-price'>
//                                     <span style={{color:"orange"}}>$</span>
//                                     <span>{card.price}</span>
//                                 </span>
//                                 <span className='primaryText'>{card.name}</span>
//                                 <span className='secondaryText'>{card.details}</span>
//                             </div>
//                         </SwiperSlide>
//                     ))
//                 }
//             </Swiper>
//         </div>
//     </section>
//   )
// }

// export default Residencies

// const SliderButton = ()=>{
//     const swiper = useSwiper();
//     return(
//         <div className="flexCenter r-button">
//             <button onClick={()=>swiper.slidePrev()}>&lt;</button>
//             <button onClick={()=>swiper.slideNext()}>&gt;</button>
//         </div>
//     )
// // }


// import React from 'react'

// const Residencies = () => {
//   return (
//     <div class="row row-cols-1 row-cols-md-3 g-4">
//   <div class="col">
//     <div class="card h-100">
//       <img src="..." class="card-img-top" alt="..."/>
//       <div class="card-body">
//         <h5 class="card-title">Card title</h5>
//         <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
//       </div>
//       <div class="card-footer">
//         <small class="text-body-secondary">Last updated 3 mins ago</small>
//       </div>
//     </div>
//   </div>
//   <div class="col">
//     <div class="card h-100">
//       <img src="..." class="card-img-top" alt="..."/>
//       <div class="card-body">
//         <h5 class="card-title">Card title</h5>
//         <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
//       </div>
//       <div class="card-footer">
//         <small class="text-body-secondary">Last updated 3 mins ago</small>
//       </div>
//     </div>
//   </div>
//   <div class="col">
//     <div class="card h-100">
//       <img src="..." class="card-img-top" alt="..."/>
//       <div class="card-body">
//         <h5 class="card-title">Card title</h5>
//         <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
//       </div>
//       <div class="card-footer">
//         <small class="text-body-secondary">Last updated 3 mins ago</small>
//       </div>
//     </div>
//   </div>
// </div>
//   )
// }

// export default Residencies

import React from 'react'

const Residencies = () => {
  return (
    <div>
        <section class="pt-5 pb-5 r-wrapper">
    <div class="container paddings innerWidth r-container">
        <div class="row r-head flexColStart">
            <div class="col-6">
            <span className='orangeText'>Best Choices</span>
            <br /> <br />
            <span className='primaryText'>Popular Residencies</span>
            <br /><br /> <br /><br />
            </div>
                <div class="col-12">
                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">

                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://real-estate-web.pages.dev/r1.png"/>
                                        <div class="card-body">
                                        <span style={{color:"orange"}}>$</span>
                                     <span>35,857</span>
                                            <h4 class="card-title orangeText">Aliva Priva Jardin</h4>
                                            <p class="card-text primaryText" style={{fontSize:"20px"}}>Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta</p>

                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://real-estate-web.pages.dev/r2.png"/>
                                        <div class="card-body">
                                        <span style={{color:"orange"}}>$</span>
                                     <span>55,595</span>
                                            <h4 class="card-title orangeText">Asatti Garden City</h4>
                                            <p class="card-text primaryText" style={{fontSize:"20px"}}>Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://real-estate-web.pages.dev/r3.png"/>
                                        <div class="card-body">
                                        <span style={{color:"orange"}}>$</span>
                                     <span>65,588</span>
                                            <h4 class="card-title orangeText">Citralan Puri Serang</h4>
                                            <p class="card-text primaryText" style={{fontSize:"20px"}}>Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">

                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532771098148-525cefe10c23?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=3f317c1f7a16116dec454fbc267dd8e4"/>
                                        <div class="card-body">
                                            <h4 class="card-title orangeText">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532715088550-62f09305f765?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=ebadb044b374504ef8e81bdec4d0e840"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1506197603052-3cc9c3a201bd?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=0754ab085804ae8a3b562548e6b4aa2e"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">

                                <div class="col-md-3 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=ee8417f0ea2a50d53a12665820b54e23"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532777946373-b6783242f211?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=8ac55cf3a68785643998730839663129"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532763303805-529d595877c5?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=5ee4fd5d19b40f93eadb21871757eda6"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        </div>
                                    </div>
                                </div>
                               <div class="col-md-3 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532763303805-529d595877c5?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=5ee4fd5d19b40f93eadb21871757eda6"/>
                                        <div class="card-body">
                                            <h4 class="card-title">Special title treatment</h4>
                                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}

export default Residencies