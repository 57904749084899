import React from 'react'
import './Companies.css'

const Companies = () => {
  return (
    <section className='c-wrapper'>
        <div className="padding innerWidth flexCenter c-container">
            <img src="https://real-estate-web.pages.dev/prologis.png" alt="" />
            <img src="https://real-estate-web.pages.dev/tower.png" alt="" />
            <img src="https://real-estate-web.pages.dev/equinix.png" alt="" />
            <img src="https://real-estate-web.pages.dev/realty.png" alt="" />
        </div>
    </section>
  )
}

export default Companies