// import React from 'react'
// import './Hero.css'


// const Hero = () => {
//   return (
//     <section className='hero-wrapper'>
//         <div className='paddings innerwidth flexCenter hero-container'>
//             <div className='flexColStart hero-left'>
//                 <div className="hero-title">
//                     <div className="orange-circle"/>
//                     <h1>Hello jani</h1>
//                 </div>
//                 <div className="flexColStart hero-des">
//                     <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis expedita a laudantium veritatis laborum. Vel a maxime, facere molestia</span>
//                     <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis expedita a laudantium veritatis laborum. Vel a maxime, facere molestia</span>
//                 </div>
//                 <div className="flexCenter search-bar ">
//                     <input type="text" />
//                     <button className='button'>Search bar</button>
//                 </div>
//             </div>
//             <div className='flexCenter hero-right '>
//                 <div className="image-container">
//                     <img src="https://real-estate-web.pages.dev/hero-image.png" alt="" />
//                 </div>
//             </div>
//         </div>
//     </section>
//   )
// }

// export default Hero


import React from 'react'
import './Hero.css'
import CountUp from "react-countup"

const Hero = () => {
  return (
    <section className='hero-wrapper white-gradient'>
        <div className="paddings innerwidth flexCenter hero-container">

            <div className="flexColStart hero-left">
                <div className="hero-title" style={{marginLeft:"100px"}}>
                <div className="orange-circle"/>
                    <h1>Discover<br/>
Most Suitable<br/>
Property</h1>
                </div>
                <div className="flexColStart hero-des" style={{marginLeft:"100px"}}>
                    <span className='secondaryText'>Find a variety of properties that suit you very easilty</span>
                    <span  className='secondaryText'>Forget all difficulties in finding a residence for you</span>
                </div>
                <div className="flexCenter search-bar" style={{marginLeft:"100px",marginBottom:"30px"}}>
                    <input type="text" />
                     <button className='button'>Search bar</button>
                 </div>

                 <div className="flexCenter stats" style={{marginLeft:"70px"}}>
                    <div className="flexColCenter stat">
                        <span>
                            <CountUp start={8000} end={9000} duration={4}/>
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>Premium Products</span>
                    </div>

                    <div className="flexColCenter stat">
                        <span>
                            <CountUp start={2000} end={3000} duration={4}/>
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>Happy Customers</span>
                    </div>
                    <div className="flexColCenter stat">
                        <span>
                            <CountUp end={28} />
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>Award Wining</span>
                    </div>
                 </div>
            </div>


            <div className="flexCenter hero-right" style={{marginRight:"100px"}}>
                <div className="image-container">
                    <img src="https://real-estate-web.pages.dev/hero-image.png" alt="" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero