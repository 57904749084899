import React from 'react'
import './Header.css'
import {BiMenuAltRight} from 'react-icons/bi'

const Header = () => {
  return (
    <section className='h-wrapper'>
        <div className='flexCenter paddings h-container'>
            <img src="https://real-estate-web.pages.dev/logo.png" alt="" />
            <div className='flexCenter h-menu'>
                <a href="">Resident</a>
                <a href="">Our Value</a>
                <a href="">Contact us</a>
                <a href="">Get Started</a>
                <button className='button'>
                    <a href="">Contact</a>
                </button>
            </div>
        </div>
        <div className="menu-icon">
          <BiMenuAltRight size={30}/>
        </div>
    </section>
  )
}

export default Header