import React from 'react'
import './Contact.css'
import { MdCall } from 'react-icons/md'
import {BsFillChatDotsFill} from 'react-icons/bs'


const Contact = () => {
  return (
    <section className="c-wrapper">
        <div className="paddings innerWidth flexCenter c-container">
            <div className="flexColStart c-left">
                <span className='orangeText'>Our Contacts</span>
                <span className="primaryText">Easy to Contact</span>
                <span className="secondaryText">hey</span>
                <br />
                <div className="flexColStart contactModes">
                    <div className="flexColStart row">
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <MdCall size={25}/>
                                </div>
                                <div className="flexColStart details">
                                    <span className='primaryText'>Call</span>
                                    <span className='secondaryText'>021 123 456 858</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Call Now</div>
                        </div>

                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart details">
                                    <span className='primaryText'>Chat</span>
                                    <span className='secondaryText'>text me for any problem</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Chat Now</div>
                        </div>
                    </div>

                    {/* secondrow */}
                    <div className="flexColStart row" style={{marginLeft:"360px",marginTop:"-380px"}}>
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <MdCall size={25}/>
                                </div>
                                <div className="flexColStart details">
                                    <span className='primaryText'>Video Call</span>
                                    <span className='secondaryText'>call me for nay problem</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Video Call Now</div>
                        </div>

                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart details">
                                    <span className='primaryText'>Message</span>
                                    <span className='secondaryText'>text me for any problem</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Message Now</div>
                        </div>
                    </div>                    
                </div>
                <div className="flexColStart row" style={{marginLeft:"700px",marginTop:"-550px"}}>
                <div className="image-container">
                    <img src="https://real-estate-web.pages.dev/contact.jpg"  alt="" />
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Contact